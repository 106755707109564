var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{staticClass:"pb-5 mt-sm-5",style:('min-width: 100% !important'),attrs:{"id":"tableBoxDay"}},[_c('tr',[_c('th',{attrs:{"colspan":"1"}}),_c('th',{attrs:{"colspan":"1"}}),_vm._l((_vm.table),function(key){return _c('th',{key:key.id,staticClass:"pb-0",staticStyle:{"text-transform":"uppercase","text-decoration":"underline","position":"relative"}},[(key.order_by > 1 && _vm.$store.getters['auth/isStudio'])?_c('v-btn',{staticStyle:{"margin-top":"-7px","position":"absolute","left":"0"},attrs:{"icon":""},on:{"click":function($event){return _vm.moveUp(key)}}},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("mdi-arrow-left-thick")])],1):_vm._e(),_c('span',{style:(_vm.$store.getters['auth/isStudio']
              ? 'display: inline; cursor: pointer'
              : 'display: inline; cursor: default !important'),on:{"click":function($event){_vm.$store.getters['auth/isStudio']
              ? _vm.$router.push({
                  name: 'boxView',
                  params: { id: key.id },
                })
              : null}}},[_vm._v(_vm._s(key.name))]),(
            key.order_by < _vm.table.length && _vm.$store.getters['auth/isStudio']
          )?_c('v-btn',{staticStyle:{"margin-top":"-7px","position":"absolute","right":"0"},attrs:{"icon":""},on:{"click":function($event){return _vm.moveDown(key)}}},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("mdi-arrow-right-thick")])],1):_vm._e()],1)})],2),_c('tr',[_c('th',{attrs:{"colspan":"1"}}),_c('th',{attrs:{"colspan":"1"}}),_vm._l((_vm.table),function(key){return _c('th',{key:key.id},[(key.tattooer_id != null)?_c('div',{staticClass:"mb-1",on:{"click":function($event){_vm.$store.getters['auth/isStudio']
              ? _vm.$router.push({
                  name: 'tattooerView',
                  params: { id: key.tattooer.user.id },
                })
              : ''}}},[(
              _vm.$store.getters['auth/isStudio'] ||
              (_vm.$store.getters['auth/isTattooer'] &&
                key.tattooer.user.id == _vm.$store.state.auth.user.tattooer.id)
            )?_c('div',{staticClass:"rounded-pill pt-0",style:(_vm.$store.getters['auth/isStudio']
                ? ("\n                border: 2px solid var(--v-primary-base);\n                height: 30px;\n                line-height: 25px; \n                white-space: nowrap;\n                text-overflow: ellipsis;\n                overflow: hidden;\n                background-color:" + (key.tattooer.color) + ";\n                cursor: pointer;\n                font-weight: normal;\n                color: " + (_vm.$store.getters['app/isLight'](key.tattooer.color)
                    ? 'white'
                    : 'black'))
                : ("border: 2px solid var(--v-primary-base);\n                height: 30px;\n                line-height: 25px; \n                background-color:" + (key.tattooer.color) + ";\n                font-weight: normal;\n                cursor: default;\n                color: " + (_vm.$store.getters['app/isLight'](key.tattooer.color)
                    ? 'white'
                    : 'black')))},[_vm._v(" "+_vm._s(key.tattooer.user.first_name)+" "+_vm._s(key.tattooer.user.last_name)+" ")]):_vm._e()]):_c('p')])})],2),_vm._l((_vm.hours),function(h,i){return _c('tr',{key:h},[(_vm.hours.length > i + 1)?_c('td',{style:(h.indexOf(':00') === -1 ? 'visibility: hidden; border-top:0px' : '')},[_c('span',[_vm._v(_vm._s(h))])]):_c('td',{},[_c('span',[_vm._v(_vm._s(+_vm.hours[i - 1].split(":")[0] + 1)+":"+_vm._s(_vm.hours[i].split(":")[1]))])]),_c('td',{style:(h.indexOf(':00') === -1 ? ' border-top:0px' : ''),attrs:{"colspan":"1","width":"1px"}}),_vm._l((_vm.table),function(box){return [(
            _vm.tattooerHours(h, box).reserva && _vm.tattooerHours(h, box).colspan > 0
          )?_c('td',{key:box.id,staticStyle:{"cursor":"pointer","border":"2px solid var(--v-primary-base)"},style:([
            {
              'background-color':
                _vm.$store.getters['auth/isTattooer'] &&
                _vm.tattooerHours(h, box).reserva.tattooer.id !==
                  _vm.$store.state.auth.user.tattooer.id
                  ? 'var(--v-primary-base)'
                  : _vm.tattooerHours(h, box).color,
            },
            {
              'border-color':
                _vm.$store.getters['auth/isTattooer'] &&
                _vm.tattooerHours(h, box).reserva.tattooer.id !==
                  _vm.$store.state.auth.user.tattooer.id
                  ? 'var(--v-primary-base)'
                  : 'var(--v-primary-base)',
            } ]),attrs:{"rowspan":_vm.tattooerHours(h, box).colspan},on:{"click":function($event){_vm.$store.getters['auth/isTattooer'] &&
            _vm.tattooerHours(h, box).reserva.tattooer.id !==
              _vm.$store.state.auth.user.tattooer.id
              ? null
              : _vm.showEvent({
                  event: _vm.tattooerHours(h, box).reserva,
                  box: box,
                  nativeEvent: $event,
                })}}},[(
              !(
                _vm.$store.getters['auth/isTattooer'] &&
                _vm.tattooerHours(h, box).reserva.tattooer.id !==
                  _vm.$store.state.auth.user.tattooer.id
              )
            )?[(_vm.tattooerHours(h, box).colspan === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({staticClass:"tattooer_name single-line"},on),[_vm._v(" . ")])]}}],null,true)},[_vm._v(" "+_vm._s(!_vm.tabs.tab_day ? _vm.tattooerHours(h, box).reserva.tattooer.user.first_name : _vm.tattooerHours(h, box).reserva.appointments ? _vm.tattooerHours(h, box).reserva.appointments.customer.user .first_name : _vm.tattooerHours(h, box).reserva.tattooer.user.first_name)+" "+_vm._s(!_vm.tabs.tab_day ? _vm.tattooerHours(h, box).reserva.tattooer.user.last_name : _vm.tattooerHours(h, box).reserva.appointments ? _vm.tattooerHours(h, box).reserva.appointments.customer.user .last_name : _vm.tattooerHours(h, box).reserva.tattooer.user.last_name)+" ")]):_c('span',{staticClass:"tattooer_name"},[_vm._v(" "+_vm._s(!_vm.tabs.tab_day ? _vm.tattooerHours(h, box).reserva.tattooer.user.first_name : _vm.tattooerHours(h, box).reserva.appointments ? _vm.tattooerHours(h, box).reserva.appointments.customer.user .first_name : _vm.tattooerHours(h, box).reserva.tattooer.user.first_name)+" "+_vm._s(!_vm.tabs.tab_day ? _vm.tattooerHours(h, box).reserva.tattooer.user.last_name : _vm.tattooerHours(h, box).reserva.appointments ? _vm.tattooerHours(h, box).reserva.appointments.customer.user .last_name : _vm.tattooerHours(h, box).reserva.tattooer.user.last_name)+" ")])]:[_vm._v(" Ocupado ")]],2):(_vm.rowspan(h, box))?_c('td',{key:box.id,class:[
            {
              gris2: _vm.checkTime()(
                h,
                _vm.dateState,
                _vm.$store.getters['auth/isStudio']
                  ? _vm.$store.state.auth.user.studio.id
                  : _vm.$store.state.boxes.studioId,
                _vm.$store.getters['auth/isStudio']
                  ? null
                  : _vm.$store.state.auth.user.tattooer.id,
                box.id
              ),
            } ],style:(h.indexOf(':00') === -1 ? ' border-top:0px' : ''),on:{"click":function($event){return _vm.reserva({
              reserva: { start: h, id: box.id, date: _vm.dateState },
              action: 'save',
            })}}}):_vm._e()]})],2)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }