<template>
  <div>
    <v-simple-table
      class="pb-5 mt-sm-5"
      id="tableBoxDay"
      :style="'min-width: 100% !important'"
    >
      <tr>
        <th colspan="1"></th>
        <th colspan="1"></th>
        <th
          :key="key.id"
          v-for="key in table"
          style="
            text-transform: uppercase;
            text-decoration: underline;
            position: relative;
          "
          class="pb-0"
        >
          <v-btn
            style="margin-top: -7px; position: absolute; left: 0"
            v-if="key.order_by > 1 && $store.getters['auth/isStudio']"
            icon
            @click="moveUp(key)"
            ><v-icon size="25">mdi-arrow-left-thick</v-icon></v-btn
          >
          <span
            @click="
              $store.getters['auth/isStudio']
                ? $router.push({
                    name: 'boxView',
                    params: { id: key.id },
                  })
                : null
            "
            :style="
              $store.getters['auth/isStudio']
                ? 'display: inline; cursor: pointer'
                : 'display: inline; cursor: default !important'
            "
            >{{ key.name }}</span
          >
          <v-btn
            style="margin-top: -7px; position: absolute; right: 0"
            v-if="
              key.order_by < table.length && $store.getters['auth/isStudio']
            "
            icon
            @click="moveDown(key)"
            ><v-icon size="25">mdi-arrow-right-thick</v-icon></v-btn
          >
        </th>
      </tr>
      <tr>
        <th colspan="1"></th>
        <th colspan="1"></th>
        <th :key="key.id" v-for="key in table">
          <div
            class="mb-1"
            @click="
              $store.getters['auth/isStudio']
                ? $router.push({
                    name: 'tattooerView',
                    params: { id: key.tattooer.user.id },
                  })
                : ''
            "
            v-if="key.tattooer_id != null"
          >
            <div
              v-if="
                $store.getters['auth/isStudio'] ||
                ($store.getters['auth/isTattooer'] &&
                  key.tattooer.user.id == $store.state.auth.user.tattooer.id)
              "
              class="rounded-pill pt-0"
              :style="
                $store.getters['auth/isStudio']
                  ? `
                  border: 2px solid var(--v-primary-base);
                  height: 30px;
                  line-height: 25px; 
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  background-color:${key.tattooer.color};
                  cursor: pointer;
                  font-weight: normal;
                  color: ${
                    $store.getters['app/isLight'](key.tattooer.color)
                      ? 'white'
                      : 'black'
                  }`
                  : `border: 2px solid var(--v-primary-base);
                  height: 30px;
                  line-height: 25px; 
                  background-color:${key.tattooer.color};
                  font-weight: normal;
                  cursor: default;
                  color: ${
                    $store.getters['app/isLight'](key.tattooer.color)
                      ? 'white'
                      : 'black'
                  }`
              "
            >
              {{ key.tattooer.user.first_name }}
              {{ key.tattooer.user.last_name }}
            </div>
          </div>
          <p v-else></p>
        </th>
      </tr>
      <tr :key="h" v-for="(h, i) in hours">
        <td
          v-if="hours.length > i + 1"
          :style="
            h.indexOf(':00') === -1 ? 'visibility: hidden; border-top:0px' : ''
          "
        >
          <span>{{ h }}</span>
        </td>

        <td v-else style="">
          <span
            >{{ +hours[i - 1].split(":")[0] + 1 }}:{{
              hours[i].split(":")[1]
            }}</span
          >
        </td>
        <td
          colspan="1"
          width="1px"
          :style="h.indexOf(':00') === -1 ? ' border-top:0px' : ''"
        ></td>
        <template v-for="box in table">
          <td
            :key="box.id"
            v-if="
              tattooerHours(h, box).reserva && tattooerHours(h, box).colspan > 0
            "
            :rowspan="tattooerHours(h, box).colspan"
            style="cursor: pointer; border: 2px solid var(--v-primary-base)"
            v-bind:style="[
              {
                'background-color':
                  $store.getters['auth/isTattooer'] &&
                  tattooerHours(h, box).reserva.tattooer.id !==
                    $store.state.auth.user.tattooer.id
                    ? 'var(--v-primary-base)'
                    : tattooerHours(h, box).color,
              },
              {
                'border-color':
                  $store.getters['auth/isTattooer'] &&
                  tattooerHours(h, box).reserva.tattooer.id !==
                    $store.state.auth.user.tattooer.id
                    ? 'var(--v-primary-base)'
                    : 'var(--v-primary-base)',
              },
            ]"
            @click="
              $store.getters['auth/isTattooer'] &&
              tattooerHours(h, box).reserva.tattooer.id !==
                $store.state.auth.user.tattooer.id
                ? null
                : showEvent({
                    event: tattooerHours(h, box).reserva,
                    box: box,
                    nativeEvent: $event,
                  })
            "
          >
            <template
              v-if="
                !(
                  $store.getters['auth/isTattooer'] &&
                  tattooerHours(h, box).reserva.tattooer.id !==
                    $store.state.auth.user.tattooer.id
                )
              "
            >
              <v-tooltip bottom v-if="tattooerHours(h, box).colspan === 1">
                <template v-slot:activator="{ on }">
                  <div class="tattooer_name single-line" v-on="on">
                    .
                    <!--

                    {{
                      !tabs.tab_day
                        ? tattooerHours(h, box).reserva.tattooer.user.first_name
                        : tattooerHours(h, box).reserva.appointments
                        ? tattooerHours(h, box).reserva.appointments.customer
                            .user.first_name
                        : tattooerHours(h, box).reserva.tattooer.user.first_name
                    }}
                    {{
                      !tabs.tab_day
                        ? tattooerHours(h, box).reserva.tattooer.user.last_name
                        : tattooerHours(h, box).reserva.appointments
                        ? tattooerHours(h, box).reserva.appointments.customer
                            .user.last_name
                        : tattooerHours(h, box).reserva.tattooer.user.last_name
                    }}
                   -->
                  </div>
                </template>

                {{
                  !tabs.tab_day
                    ? tattooerHours(h, box).reserva.tattooer.user.first_name
                    : tattooerHours(h, box).reserva.appointments
                    ? tattooerHours(h, box).reserva.appointments.customer.user
                        .first_name
                    : tattooerHours(h, box).reserva.tattooer.user.first_name
                }}
                {{
                  !tabs.tab_day
                    ? tattooerHours(h, box).reserva.tattooer.user.last_name
                    : tattooerHours(h, box).reserva.appointments
                    ? tattooerHours(h, box).reserva.appointments.customer.user
                        .last_name
                    : tattooerHours(h, box).reserva.tattooer.user.last_name
                }}
              </v-tooltip>
              <span v-else class="tattooer_name">
                {{
                  !tabs.tab_day
                    ? tattooerHours(h, box).reserva.tattooer.user.first_name
                    : tattooerHours(h, box).reserva.appointments
                    ? tattooerHours(h, box).reserva.appointments.customer.user
                        .first_name
                    : tattooerHours(h, box).reserva.tattooer.user.first_name
                }}
                {{
                  !tabs.tab_day
                    ? tattooerHours(h, box).reserva.tattooer.user.last_name
                    : tattooerHours(h, box).reserva.appointments
                    ? tattooerHours(h, box).reserva.appointments.customer.user
                        .last_name
                    : tattooerHours(h, box).reserva.tattooer.user.last_name
                }}
              </span>
            </template>
            <template v-else> Ocupado </template>
          </td>
          <td
            :key="box.id"
            v-else-if="rowspan(h, box)"
            @click="
              reserva({
                reserva: { start: h, id: box.id, date: dateState },
                action: 'save',
              })
            "
            :style="h.indexOf(':00') === -1 ? ' border-top:0px' : ''"
            v-bind:class="[
              {
                gris2: checkTime()(
                  h,
                  dateState,
                  $store.getters['auth/isStudio']
                    ? $store.state.auth.user.studio.id
                    : $store.state.boxes.studioId,
                  $store.getters['auth/isStudio']
                    ? null
                    : $store.state.auth.user.tattooer.id,
                  box.id
                ),
              },
            ]"
          ></td>
        </template>
      </tr>
    </v-simple-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "CalendarDay",
  data() {
    return {
      table: [],
    };
  },

  watch: {
    dateState: {
      handler() {
        this.fetchTable();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("boxes", ["dateState", "tabs"]),
    ...mapGetters("boxes", ["hoursRow", "timeTo", "timeFrom", "mins", "today"]),
    ...mapGetters("timetables", [
      "hours",
      "firstHourGlobal",
      "lastHourGlobal",
      "mins",
    ]),
  },
  mounted() {
    this.fetchTable();
  },
  methods: {
    ...mapGetters("timetables", ["checkTime"]),

    ...mapActions("boxes", ["getTable", "orderBox"]),
    ...mapMutations("boxes", ["showEvent", "reserva"]),
    fetchTable() {
      //console.log("%c%s", "color: #733d00", "date", this.date);
      this.getTable({
        date: this.dateState,
        studio: this.$store.state.boxes.studioId,
      }).then((boxes) => {
        this.table = boxes.box;
      });
    },
    tattooerHours(h, box) {
      let n = this.table.find((element) => element.id == box.id);
      if (n) {
        let hs = n.boxes_tattooer.find((x) => x.start_time == h);
        if (hs) {
          let span = 0;
          let end = parseInt(hs.end_time);
          let start = parseInt(hs.start_time);
          for (let x = start; x <= end; x++) {
            this.mins.forEach((y) => {
              let t = `${x < 10 ? "0" + x : x}${y}`;
              //console.log(t);
              if (hs.start_time <= t && hs.end_time > t) span++;
            });
          }
          return { reserva: hs, colspan: span, color: hs.tattooer.color };
        }
      }

      return { reserva: null, colspan: 1 };
    },
    rowspan(h, box) {
      let n = this.table.find((element) => element.id == box.id);
      if (n) {
        let hs = n.boxes_tattooer.find((x) => {
          return h > x.start_time && h < x.end_time;
        });
        ////console.log(n.boxes_tattooer[0].start_time == h);

        if (hs) {
          return false;
        }
      }
      return true;
    },

    moveUp(value) {
      this.orderBox({ id: 1, order: value }).then((response) => {
        if (response) {
          this.errors.clear();
          this.fetchTable();
        }
      });
    },
    moveDown(value) {
      this.orderBox({ id: 0, order: value }).then((response) => {
        if (response) {
          this.errors.clear();
          this.fetchTable();
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
#tableBoxDay
  margin-bottom: 20px !important
  min-width: 1000px
  padding-left: 40px
  width: auto
  background-color: var(--v-gris3-base)
  tr:first-child th
    border: 0
    min-width: 50px
    font-size: 16px !important
  tr:nth-child(2) th
    border: 0
  td:first-child, th:first-child
    border-left: 0
    border-top: 0
    min-width: 30px
    width: 50px
    position: absolute
  tr:last-child td:first-child
    border-bottom: 0
    span
      margin-top: -4px !important
  tr:last-child
    td
      height: 0
      max-height: 0
  td
    height: 12px
    max-height: 12px

    span
      display: block
      &:not(.tattooer_name)
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
      &.tattooer_name
        color: black
        &.single-line
          width: 100%
          height: 100%
    div.tattooer_name.single-line
      width: 100%
      height: 100%
      font-size: 0
  td:first-child span
    position: absolute
    margin-top: -10px
    margin-left: -50px
  tr, td:first-child, th:first-child
    border-left: 0
    border-right: 0

  tr:last-child td
    border-bottom: 1px solid var(--v-primary-base)
  td, th
    border-top: 1px solid var(--v-primary-base)
    border-right: 1px solid var(--v-primary-base)

    border-color: var(--v-primary-base) !important

    padding: 0 !important

    min-width: 120px
    max-width: 120px
    text-align: center
  th
    padding: 4px !important
  td:nth-child(2), th:nth-child(2)
    min-width: 20px !important
    width: 20px
    border-left: 0
    border-bottom: 0
    border-top: 1px solid var(--v-primary-base)
</style>